<template>
    <div class="service">
        <div class="banner"> 
            <div class="banner-container">
                <div>
                    <h2>服务流程</h2>
                    <h4></h4>
                    <p>为您提供专业高效的商业化解决方案</p>
                </div>
                <img src="../assets/img/fwlc.png" alt="#">
            </div>
        </div>
        <div class="base-flow">
            <div class="flowImg">
                <h2>基础流程图</h2>
                <div>
                    <img src="../assets/img/flow_01.png" alt="##">
                </div>
                <div>
                    <p ><span>用户注册：</span><i>点击 <a href="https://ssp.adyounger.com/">注册</a> ，填写资料，完成注册。</i></p>
                    <p ><span>供应商资料审核：</span><i>联系专属商务，完成供应商资料审核。</i></p>
                    <p ><span>签订合同：</span><i>联系专属商务，完成流量合作合同的签订。</i></p>
                    <p ><span>流量合作：</span><i>创建媒体和广告位，集成代码，完成广告投放。</i></p>
                    <p ><span>收益结算：</span><i>获取流量收益报告。</i></p>
                    <p ><span>财务付款：</span><i>联系专属商务完善财务信息，开具发票，获取财务付款。</i></p>
                </div>
            </div>
        </div>
        <div class="content">
            <div class="content-container">
                <ul>
                    <li 
                    v-for="item,index in flowList" 
                    :key='item.title'
                    @click="activeLi = index"
                    :class="{active:activeLi == index}"
                    >
                        <div>
                            <img :src="item.imgUrl" alt="#">
                        </div>
                        <p>{{item.title}}</p>
                    </li>
                </ul>
                <div>
                    <h2>{{flowList[activeLi].title}}</h2>
                    <p>{{flowList[activeLi].intro}}</p>
                    <div>
                        <p v-for="item in flowList[activeLi].step" :key="item.name">
                            <span>{{item.name}}</span><i>{{item.detail}}</i>
                        </p>
                        <img v-show='flowList[activeLi].showImg' :src="flowList[activeLi].showImg" alt="##">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
         data() {
            return {
                flowList: [{
                    title:'用户注册',
                    imgUrl:require('../assets/img/yhzc.png'),
                    intro:'供应商需要填写以下信息来完成扬歌SSP帐号注册。',
                    step:[{
                        name:'注册邮箱：',
                        detail:'限制为标准邮箱，用于提交成功后程序将发送账号激活邮件。'
                    },{
                        name:'密码：',
                        detail:'帐号的登录密码。'
                    },{
                        name:'用户类型：',
                        detail:'供应商类型，包括企业和个人两种类型，我们将根据供应商的类型来签订对应合同和财务结算，请正确填写。'
                    },{
                        name:'联系人：',
                        detail:'供应商对接人的真实姓名。'
                    },{
                        name:'联系手机：',
                        detail:'必须填写真实手机号码，便于快速处理业务问题。'
                    },{
                        name:'激活码：',
                        detail:'邮箱验证码，若未收到，则可通过“发送激活码”重新发送。'
                    },{
                        name:'扬歌用户协议：',
                        detail:'平台注册协议，提交注册便表示同意，注册前请仔细阅读。'
                    },]
                },{
                    title:'审核资料',
                    imgUrl:require('../assets/img/shzl.png'),
                    intro:'供应商注册后，我们将对您的注册资料进行审核，并分配一个专属商务来解决您在流量变现中遇到的每一个问题。',
                    step:[{
                        name:'资料提交：',
                        detail:'注册成功后，我们的专属商务将会第一时间联系您提供主体相关资料。'
                    },{
                        name:'资料审核：',
                        detail:'资料提交后，我们将对供应商资料进行审核，主要包括主体信息的真实性和完整性，您的专属商务将五个工作日内通知审核结果。'
                    }]
                },{
                    title:'合同签订',
                    imgUrl:require('../assets/img/htqd.png'),
                    intro:'资料审核通过后，您的专属商务会联系进行合同的签订。',
                    step:[{
                        name:'合同签订：',
                        detail:'合同的签订分为公司类型和个人类型，公司类型的供应商的合同签订主体为本公司，个人类型合同签订主体为第三方代理公司，详情请咨询您的专属商务。'
                    },{
                        name:'合同审核：',
                        detail:'合同签订后，我们将对合同进行审核，双方加盖公章后方可生效。'
                    }]
                },{
                    title:'流量合作',
                    imgUrl:require('../assets/img/llhz.png'),
                    intro:'您成功注册成为扬歌SSP会员后，即可与我们进行流量合作，相关流程如下：',
                    step:[],
                    showImg:require('../assets/img/flow_02.png')
                },{
                    title:'收益结算',
                    imgUrl:require('../assets/img/syjs.png'),
                    intro:'收益分为预估收益和结算收益，财务付款以结算收益为准。',
                    step:[{
                        name:'预估收益：',
                        detail:'预估收益是为您提供每天收益的近似值，在数据报表中可以查看每一个广告位产生的预估收益数据。'
                    },{
                        name:'结算收益：',
                        detail:'结算收益是指您每月应该获得的流量变现实际金额。它是以预估收益为参考基础，通过扬歌运营和财务审核后，产生的实际支付金额。'
                    },{
                        name:'结算周期：',
                        detail:'扬歌SSP平台收益结算采用月结的方式。每月15日-25日结算上一月的收益，超过每月最后一天未收到款项或退票通知可联系专属商务排查。'
                    }]
                },{
                    title:'财务结算',
                    imgUrl:require('../assets/img/cwfk.png'),
                    intro:'根据结算收益，进行财务结算。',
                    step:[{
                        name:'财务对账：',
                        detail:'双方进行财务对账，确认财务支付金额。'
                    },{
                        name:'发票开具：',
                        detail:'根据对账金额开具等额的发票与我方。'
                    },{
                        name:'付款：',
                        detail:'我方在收到发票后，将按照发票上的确认金额支付到您的指定银行账户。如果您是与第三方代理公司，由第三方代理公司签订的合同，则由第三方代理公司代为支付给您。'
                    }]
                }],
                activeLi:0
            }
        },
    }
</script>

<style lang="less" scoped>
    @import url('../assets/css/theme-color.less');
    .service{
        .banner{
            background:url('../assets/img/type-banner-bg.png');
            background-size: contain;
            .banner-container{
                width: 1200px;
                margin: 0 auto;
                position: relative;
                display: flex;
                align-items: center;
                height: 430px;
                img{
                    position: absolute;
                    top: -40px;
                    right: 0;
                    width: 500px;
                }
                >div{
                    h2{
                        font-size: 40px;
                        font-weight: 600;
                    }
                    h4{
                        background: @themeColor;
                        width: 32px;
                        height: 4px;
                        margin: 12px 0 24px;
                    }
                    p{
                        font-size: 18px;
                        width: 530px;
                    }
                }
            }
        }
        .base-flow{
            background: #fefefe;
            .flowImg{
                padding: 60px 0 100px;
                h2{
                    font-size: 28px;
                    font-weight: 600;
                    padding: 30px 0 12px;
                    text-align: center;
                }
                >p{
                    color: #898989;
                    text-align: center;
                }
                >div{
                    padding: 36px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    p{
                        display: flex;
                        line-height: 36px;
                        span{
                            display: inline-block;
                            width:160px;
                            text-align: right;
                            margin-right: 20px;
                            font-weight: 600;
                        }
                        i{
                            width: 580px;
                            display: inline-block;
                            text-align: left;
                        }
                        a{
                            color: @themeColor;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        .content{
            // height: 1260px;
            .content-container{
                width: 1200px;
                margin: 0 auto;
                ul{
                    display: flex;
                    justify-content: space-between;
                    padding: 26px 0;
                    position: relative;
                    top: -60px;
                    li{
                        background: #f6f7f8;
                        height: 90px;
                        width: 16%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        box-shadow: 3px 3px 10px #ceeefa99;
                        border-radius: 6px;
                        transition: .3s;
                        div{
                            width: 60px;
                            height: 40px;
                            border-radius: 8px;
                            border:1px solid @themeColor;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-bottom: 12px;
                            img{
                                width:38%;
                            }
                        }
                        &.active{
                            background: #ceeefa99;
                            color: @themeColor;
                        }
                        &:hover{
                            background: #ceeefa99;
                            color: @themeColor;
                            position: relative;
                            transform: translateY(-3px);
                        }
                    }
                }
                >div{
                    h2{
                        font-size: 28px;
                        font-weight: 600;
                        padding: 30px 0 20px;
                        text-align: center;
                    }
                    >p{
                        color: #898989;
                        text-align: center;
                    }
                    >div{
                        padding: 36px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        p{
                            display: flex;
                            line-height: 36px;
                            span{
                                display: inline-block;
                                width:160px;
                                text-align: right;
                                margin-right: 20px;
                                font-weight: 600;
                            }
                            i{
                                width: 780px;
                                display: inline-block;
                                text-align: left;
                            }
                        }
                    }
                    &:nth-of-type(1){
                        height:666px;
                        overflow: hidden;
                        >div{
                            img{
                                width: 60%;
                            }
                        }
                    }
                   
                }
            }
        }
    }
   
</style>